.wrapper {
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
}

.qr {
  border: 2px solid #f7f7f7;
  border-radius: 12px;
  position: relative;
}

@media screen and (max-width: 1024px) {
  .content {
    flex-wrap: nowrap;
  }

  .qr {
    width: 140px;
  }
}
