.row {
  margin-bottom: 10px;
  color: #6c707a;
}

.heading {
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 5px;
  color: #000;
}
