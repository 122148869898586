.link {
  color: #42536e;
  text-decoration: none;
  transition: color 300ms ease;

  &:hover {
    color: #000;
  }
}

@media screen and (max-width: 640px) {
  .link {
    width: 100%;
  }
}
