.wrapper {
  display: flex;
  width: 100%;
  height: 100%;
  padding: 30px;
  align-items: center;
  justify-content: center;

  background-image: url(~assets/bg.png);
  background-repeat: no-repeat;
  background-size: contain;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.image {
  max-height: 300px;
}
