.link {
  color: #2e292b;
  display: inline-flex;
  text-decoration: none;
  align-items: center;
  cursor: pointer;

  svg {
    margin-right: 12px;
  }

  &:hover {
    text-decoration: underline;
  }
}
