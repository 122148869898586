.plan {
  display: flex;
  flex-direction: column;
  flex: 1;
  border: #f7f7f7 2px solid;
  border-radius: 12px;
  text-align: center;
  position: relative;

  &.disabled {
    opacity: 0.5;
  }
}

.row {
  padding: 13px;
  border-bottom: #f7f7f7 2px solid;
}

.title {
  padding-top: 20px;
  padding-bottom: 20px;
}

.price {
  font-size: 20px;
  font-weight: 500;
}

.description {
  flex: 1;
  white-space: pre-line;
}

.actions {
  display: flex;
  gap: 5px;
  padding: 13px 24px;
}
