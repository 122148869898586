.code {
  display: inline-block;
  vertical-align: top;
  font-size: 27px;
  border: #f7f7f7 2px solid;
  border-radius: 12px;
  padding: 5px 15px 5px 20px;
  letter-spacing: 5px;
}

@media screen and (max-width: 640px) {
  .avatar {
    display: none;
  }

  .row {
    flex-direction: column;
  }

  .code {
    padding: 0;
    border: none;
    font-size: 16px;
    letter-spacing: 0;
  }
}
