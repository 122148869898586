.wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ccc;
  color: #fff;
  border-radius: 100%;
  min-width: 20px;
  width: 20px;
  height: 20px;

  &.transparent {
    background-color: #f3f3f3;
  }

  &.orange {
    background-color: #ef8861;
  }

  &.yellow {
    background-color: #d3d600;
  }

  &.blue {
    background-color: #6eb6d6;
  }

  &.cyan {
    background-color: #6ecac7;
  }

  &.grey {
    background-color: #999ca6;
  }

  &.violet {
    background-color: #5d5cd2;
  }

  &.green {
    background-color: #64c975;
  }
}

.title {
  display: inline-block;
  vertical-align: top;
  font-size: 13px;
  font-weight: 500;
}
