.profile {
  cursor: pointer;
}

.icon {
  align-self: center;
}

@media screen and (max-width: 1024px) {
  .profile {
    color: #fff;
  }

  .menu {
    color: #000;
  }

  .icon {
    display: none;
  }
}
