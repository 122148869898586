.wrapper {
  min-height: 100%;
  background-color: #fff;
}

.logo {
  height: 60px;
  border-top-right-radius: 48px;
  background-color: #252324;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 0 4px 30px;
}

.sidebar {
  background-color: #000;
  color: #fff;
  min-width: 250px;
  width: 250px;
  height: 100vh;
  position: sticky;
  top: 0;
  overflow: hidden;
  border-radius: 0 48px 48px 0;

  &.opened {
    transform: translate3d(0, 0, 0);
    border-radius: 0;

    .logo {
      border-radius: 0;
    }
  }
}

.scrollbar {
  padding-top: 16px;
  height: calc(100% - 100px);
  overflow: auto;
}

.header {
  padding: 10px 35px;
  justify-content: space-between;
  z-index: 100;
}

@media screen and (max-width: 1024px) {
  .header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 10;
    background-color: #252324;
    padding-left: 65px;
    padding-right: 20px;
    height: 60px;
  }

  .sidebar {
    position: fixed;
    transition: ease 0.3s transform;
    transform: translate3d(-100%, 0, 0);
    min-width: 100%;
    z-index: 15;
    overflow: visible;
  }

  .container {
    width: 100%;
  }

  .content {
    padding: 80px 20px 20px 20px;
  }
}
